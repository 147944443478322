import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import IconOption from '../components/00-helpers/IconOption'
import Status from '../components/01-atoms/Status'
import { SpanTip } from '../components/01-atoms/OverFlowTip'
import { staticBasePath, hasPermission } from './index'
import { ORDER_STATUS, permissionLevels } from './variables'

export const fullnameFormat = value => (
  <div> {`${value.name} ${value.last_name}`} </div>
)

export const programmedDateFormat = (
  programmedDate,
  i,
  { delivery_window_from, delivery_window_to }
) => {
  if (programmedDate) {
    return moment(programmedDate).format('lll')
  }

  if (delivery_window_from && delivery_window_to) {
    return `${moment(delivery_window_from).format('dddd DD hA')} - ${moment(
      delivery_window_to
    ).format('hA')}`
  }

  return '-'
}

export const codeFormat = (code, index, obj) => {
  return (
    <div style={{ width: 'max-content' }}>
      <span>{code}</span>
      {obj.parent_order_code && (
        <p style={{ margin: '10px 0 10px 0' }}>
          <span className="u-bold">Código Padre:</span>
          <br />
          {obj.parent_order_code}
        </p>
      )}
    </div>
  )
}

export const dateFormat = value =>
  value ? moment(value).format('lll') : 'No encontrado'

export const onlyDateFormat = value =>
  value ? moment(value).format('LL') : 'No encontrado'

export const calendarDateFormat = isoDate => {
  const date = new Date(isoDate)
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()
  return `${day}-${month}-${year}`
}

export const calendarFormat = value =>
  value ? moment(value).calendar() : 'No Encontrado'

export const timeAgoFormat = value => moment(value).fromNow()

export const stateTimeAgoFormat = value =>
  (value && value === 'idle' && (
    <OverlayTrigger
      key={'idle'}
      placement="top"
      overlay={<Tooltip id="idle">{'Generado'}</Tooltip>}
    >
      <i className="fa fa-circle" style={{ color: 'rgb(89, 178, 235)' }} />
    </OverlayTrigger>
  )) ||
  (value && value === 'in progress' && (
    <OverlayTrigger
      key={'in progress'}
      placement="top"
      overlay={<Tooltip id="in progress">{'En progreso'}</Tooltip>}
    >
      <i className="fa fa-circle" style={{ color: 'rgb(26, 179, 148)' }} />
    </OverlayTrigger>
  )) ||
  (value && value === 'ended' && (
    <OverlayTrigger
      key={'ended'}
      placement="top"
      overlay={<Tooltip id="ended">{'Finalizado'}</Tooltip>}
    >
      <i className="fa fa-circle" style={{ color: 'rgb(237, 85, 101)' }} />
    </OverlayTrigger>
  )) ||
  (value && value === 'no dates' && (
    <OverlayTrigger
      key={'no dates'}
      placement="top"
      overlay={<Tooltip id="no dates">{'Sin fechas'}</Tooltip>}
    >
      <i className="fa fa-circle" style={{ color: 'rgb(209, 218, 222)' }} />
    </OverlayTrigger>
  )) ||
  (value && value === 'paused' && (
    <OverlayTrigger
      key={'paused'}
      placement="top"
      overlay={<Tooltip id="paused">{'Pausado'}</Tooltip>}
    >
      <i className="fa fa-circle" style={{ color: 'rgb(248, 172, 89)' }} />
    </OverlayTrigger>
  ))

export const destNotification = value =>
  value && (
    <OverlayTrigger
      key={value}
      placement="top"
      overlay={<Tooltip id={`${value}`}>{value}</Tooltip>}
    >
      <i
        className={value === 'couriers' ? 'fa fa-motorcycle' : 'fa fa-users'}
      />
    </OverlayTrigger>
  )

export const bodyTooltip = value =>
  value && (
    <OverlayTrigger
      key={value}
      placement="left"
      overlay={<Tooltip id={`${value}`}>{value}</Tooltip>}
    >
      <i className="fa fa-file" />
    </OverlayTrigger>
  )

export const groupStatus = value => {
  const iconMap = {
    'Todos activos': 'fa fa-user',
    'Todos activos solo online': 'fa fa-user-circle-o',
    'Todos activos solo offline': 'fa fa-user-circle',
    'Todos desactivos': 'fa fa-user-o'
  }
  const iconClass = iconMap[value]
  if (!iconClass) {
    return null
  }
  return (
    <OverlayTrigger
      key={value}
      placement="right"
      overlay={<Tooltip id={value}>{value}</Tooltip>}
    >
      <i className={iconClass} />
    </OverlayTrigger>
  )
}

export const onlyTimeFormat = value =>
  value ? moment(value).format('HH:mm A') : 'No registra'

export const selectTimefromString = value => {
  const hr = Number(value.substring(0, 2))
  const min = Number(value.substring(3, 5))
  return `${hr} hr ${min}min `
}

export const changeTimeToString = value =>
  value
    ? `${Number(value.substring(0, 2))} hr ${Number(
        value.substring(3, 5)
      )} min`
    : 'No encontrado'

export const priceFormat = value => (value ? `S/ ${value}` : 'S/ 0.00')

export const countHours = value =>
  value ? `${Math.round((value + Number.EPSILON) * 100) / 100}` : 'Sin rango'

export const timeCount = value => (value ? `${value} minutos` : 'Sin registro')

export const numberOfMembers = value => (value === 0 ? 'No registra' : value)

export const pricePerMinute = value =>
  value ? `S/ ${Math.round((value + Number.EPSILON) * 100) / 100}` : 'S/ 0.00'

export const referencePrice = value =>
  value < 0 ? `S/ ${Math.round((value + Number.EPSILON) * 100) / 100}` : '--'

export const labelFormat = value => (value ? value : '--')

const getTranslatedDay = day => {
  switch (day) {
    case 'monday':
      return 'Lun'
    case 'tuesday':
      return 'Mar'
    case 'wednesday':
      return 'Mié'
    case 'thursday':
      return 'Jue'
    case 'friday':
      return 'Vie'
    case 'saturday':
      return 'Sáb'
    case 'sunday':
      return 'Dom'
    default:
      return day
  }
}

export const daysFormat = value => {
  const selectedDays = Object.entries(value)
    .filter(([_, value]) => value)
    .map(([day]) => day)
  return (
    <div>
      {selectedDays.map(day => (
        <span key={day}>- {getTranslatedDay(day)} </span>
      ))}
    </div>
  )
}

export const dayFormatDetail = value => {
  if (value) {
    const tab = '\u00A0'
    return (
      <div>
        {value.map((day, index) => (
          <React.Fragment key={index}>
            <span key={index} className="label label-primary">
              {getTranslatedDay(day)}
            </span>
            {tab}
          </React.Fragment>
        ))}
      </div>
    )
  }
}

export const descriptionFormat = value => (value ? value : ' -- ')

export const formatTime = value => {
  const [hours, minutes] = value.split(':')
  let formattedTime = ''

  if (hours < 12) {
    formattedTime = `${hours}:${minutes} AM`
  } else {
    const formattedHours = hours === '12' ? '12' : String(hours - 12)
    formattedTime = `${formattedHours}:${minutes} PM`
  }

  return formattedTime
}

export const actionsFormat = (config, data) => (
  <div>
    {config.map((el, i) => (
      <OverlayTrigger
        key={i}
        placement="top"
        overlay={<Tooltip id={el.label}>{el.label}</Tooltip>}
      >
        <button
          onClick={e => el.callback(e, data)}
          className="btn-white btn btn-xs"
          type="button"
        >
          <i className={`fa ${el.icon}`} />
        </button>
      </OverlayTrigger>
    ))}
  </div>
)

export const statusEditingActions = (config, data) => {
  if (data !== 1) {
    return (
      <div>
        {config.map((el, i) => (
          <OverlayTrigger
            key={i}
            placement="top"
            overlay={<Tooltip id={el.label}>{el.label}</Tooltip>}
          >
            <button className="btn-white btn btn-xs" type="button">
              <i className={`fa ${el.icon}`} />
            </button>
          </OverlayTrigger>
        ))}
      </div>
    )
  }
}

export const orderTypeLabel = (value, i, el) => {
  let formattedLabel = ''
  const taskLabel = el.task_label
  switch (value) {
    case 'pickup':
      formattedLabel = taskLabel
      break
    case 'dropoff':
      formattedLabel = taskLabel
      break
    case 'return':
      formattedLabel = (
        <span style={{ fontWeight: 'bolder', color: '#758a93' }}>
          {taskLabel}
        </span>
      )
      break
    default:
      formattedLabel = taskLabel
  }

  return <span>{formattedLabel}</span>
}

export const statusFormat = (value, i, obj) => (
  <div style={{ display: 'flex', flexFlow: 'column', maxWidth: '200px' }}>
    <Status
      className="a-statusList"
      value={{
        status_label: obj.status_label || value,
        status: obj.status,
        logic_status: obj._logic_status
      }}
    />
    {obj.current_destination_label &&
      obj.current_destination_label !== obj.status_label && (
        <Status
          className="a-statusList"
          value={{
            status_label: obj.current_destination_label || value,
            status: obj.status
          }}
        />
      )}
  </div>
)

export const permissionsFormat = (value, _, element) => (
  <div>
    {element.has_collection && (
      <div className="label u-label-white  u-margin-r-5">
        <i className="fa fa-dollar" />
      </div>
    )}
    {value.map((el, i) => (
      <div key={i} className="label u-label-white u-margin-r-5">
        {el.code}
      </div>
    ))}
  </div>
)

export const paymentDocType = value =>
  value === 1 ? (
    <span className="label label-info">FACT</span>
  ) : (
    <span className="label label-info">RH</span>
  )

export const specialCod = value =>
  value ? (
    <span className="label label-warning" style={{ fontWeight: 'bold' }}>
      COD
    </span>
  ) : (
    ''
  )

export const courierFormat = value =>
  value ? (
    <span>
      {value.name} {value.last_name}
      <br />
      {value.phone}
    </span>
  ) : (
    'No asignado'
  )

export const warrantyFormat = value =>
  value ? (
    <span>
      "{value.name}"
      <br />
      ID: {value.id}
    </span>
  ) : (
    'No generado'
  )

export const bonusFormat = value => value && <span>"{value.name}"</span>

export const courierObjFormat = (value, i, obj) => (
  <div className="client-table-group">
    <span>{`${obj.name} ${obj.last_name}`}</span>
    <span>{obj.email}</span>
    <span>{obj.phone}</span>
    {obj.ruc ? (
      <div>
        <span>Empresa:</span>
        <div className="overflow-text">{obj.ruc}</div>
      </div>
    ) : null}
  </div>
)
export const automaticFormat = value => value || 'Automático'

export const collectionFormat = objCollection =>
  objCollection
    ? `S/ ${objCollection.amount} ${objCollection.type.name} `
    : '-'

export const onlineFormat = isOnline => (
  <i
    className="fa fa-circle"
    style={{ color: isOnline ? 'rgb(26, 179, 148)' : 'gray' }}
  />
)

export const activeFormat = isActive => (
  <i className={`fa fa-${isActive ? 'check' : 'times'}`} />
)

export const addressFormat = (address, i, obj) =>
  obj.interior ? `${address} interior ${obj.interior}` : address

export const commentFormat = (value, index, destination) => {
  if (value === 'failed') {
    return destination.failed_reason
  }
  return destination.special_instructions
}

export const clientFormat = client => {
  if (client) {
    return (
      <div className="client-table-group">
        {client.is_company && (
          <span>{client.tradename || client.business_name}</span>
        )}
        <span>{`${client.name} ${
          client.last_name ? client.last_name : ''
        }`}</span>
        <span>{client.email}</span>
        <span>{client.phone}</span>
      </div>
    )
  } else {
    return <div />
  }
}

export const saleClientFormat = (value, i, obj) => (
  <div className="client-table-group">
    <span>{obj.client_fullname}</span>
    <span>{obj.client_email}</span>
    <span>{obj.client_phone}</span>
  </div>
)

export const salesStoreFormat = value => (
  <div className="client-table-group">
    <span>{value.tradename}</span>
    <span>{value.email}</span>
    <span>{value.address}</span>
    <span>{value.phone}</span>
  </div>
)

function cancelledBy(obj, value) {
  const arr = obj.state.toLowerCase().split('_')
  if (arr.includes('client') || arr.includes('merchant')) {
    return `${value} por ${arr[arr.length - 1]}`
  }
  return value
}

export const saleStateFormat = (value, i, obj) => (
  <div className="m-state" style={{ fontSize: '100%' }}>
    <div className={`m-state m-${obj.state.toLowerCase()}`}>
      {cancelledBy(obj, value)}
    </div>

    {obj.cancellation_reason && (
      <div style={{ color: 'grey' }}>
        {`Razón: ${obj.cancellation_reason}`}
      </div>
    )}
  </div>
)

export const paymemtStateFormat = value =>
  value ? (
    <div className="m-state m-created">Pagado</div>
  ) : (
    <div className="m-state m-cancelled">Pendiente</div>
  )

export const courierListFormat = courier => (
  <div className="client-table-group">
    <span>{`${courier.name} ${courier.last_name}`}</span>
    <span>{courier.email}</span>
    <span>{courier.phone}</span>
  </div>
)

export const textOverFlowFormat = value => <SpanTip text={value} />

export const emptyFormat = value => {
  if (!value) return '-'
  return textOverFlowFormat(value)
}

export const clientInvoiceFormat = client => (
  <div className="client-table-group">
    {client.is_company && (
      <SpanTip text={client.business_name || client.tradename} />
    )}
    <SpanTip
      text={`${client.name} ${client.last_name ? client.last_name : ''}`}
    />
    <SpanTip text={client.email} />
    <span>{client.phone}</span>
  </div>
)

export const vehicleFormat = vehicle => (
  <img width="50" src={vehicle.image} alt={vehicle.name} />
)

export const packageFormat = dest => {
  let dropoff = dest.find(item => {
    return item.task === 'dropoff'
  })
  let packageCode = dropoff.package_type ? dropoff.package_type.code : ''
  return <div style={{ textAlign: 'center' }}>{packageCode}</div>
}

export const quantityFormat = (value, i, obj) =>
  `${obj.registrations_quantity || '0'}/${obj.quantity || '0'}`

export const payFormat = (value, i, obj) => {
  const { credit, collection } = obj
  return (
    <div className="table-clients-center">
      <IconOption data={credit} />
      <IconOption data={collection} />
    </div>
  )
}

export const clientNameFormat = data =>
  data && data.is_company
    ? `${data.business_name} con RUC: ${data.ruc}`
    : `${data.name} ${data.last_name}`

export const madeBy = element => {
  if (!element) {
    return null
  }
  const { destination_dni, destination_contact } = element
  return (
    <div>
      <div>{destination_contact}</div>
      <div>{destination_dni}</div>
    </div>
  )
}

export const photosExtra = photos => {
  const element = []
  for (let i = 0; i < photos.length; i += 1) {
    element.push(
      <a
        style={{ marginRight: '10px' }}
        href={`${staticBasePath}${photos[i]}`}
        download={`photo_ ${i}.png`}
        className="btn btn-white btn-sm"
      >
        <i className="fa fa-file-image-o" />
      </a>
    )
  }
  return <div>{element}</div>
}

export const inRouteTakenPhotos = photos => {
  const element = []
  for (let i = 0; i < photos.length; i += 1) {
    const photoType = photos[i].split('_')[0]
    element.push(
      <a
        key={`photo_${i}`}
        style={{ marginRight: '10px' }}
        href={`${staticBasePath}${photos[i]}`}
        download={`${photoType}_${i}.png`}
        className="btn btn-white btn-sm"
      >
        <i
          className={
            photoType === 'signature'
              ? 'fa fa-address-card-o'
              : 'fa fa-file-image-o'
          }
        />
      </a>
    )
  }
  return <div>{element}</div>
}

export const receivedBy = array => {
  if (array.length) {
    const lastElementId = array.length - 1
    const validStatus = [ORDER_STATUS.FAILED, ORDER_STATUS.COMPLETED]
    for (let i = lastElementId; i >= 0; i -= 1) {
      if (validStatus.includes(array[i].status)) {
        return madeBy(array[i].extra)
      }
    }
    return '-'
  }
}

export const imageFormat = img => <img width="60" src={img} alt="" />

export const downloadFormat = (url, options) => {
  const timer = options.time ? options.time : 2000
  const name = options.name ? options.name : 'Excel de nota de credito'
  setTimeout(() => {
    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    link.download = `${name}.${options.format || 'pdf'}`
    link.dispatchEvent(new MouseEvent('click'))
  }, timer)
}

export const downloadOnlyFormat = data => (
  <button
    type="button"
    className="btn btn-default"
    onClick={() => {
      downloadFormat(data, {
        name: 'Excel',
        format: '.csv',
        time: 100
      })
    }}
  >
    <i className="fa fa-table" />
  </button>
)

export const kmDistanceFormat = value =>
  `${(parseFloat(value) / 1000).toFixed(2)} KM.`
export const kilometerFormat = value => `${value} KM.`

export const batchLoadFormat = value => {
  if (!value) return 'N / A'
  return (
    <div>
      <small>Peso: {value.status_load_kg}%</small>
      <br />
      <small>Espacio: {value.status_load_m3}%</small>
      <br />
      <small>Órdenes {value.status_load_orders}%</small>
    </div>
  )
}

export const minDurationFormat = value => {
  if (!Number.isFinite(value)) {
    return 'Valor inválido'
  }

  const minutes = value / 60
  if (minutes < 1) {
    return `${value} seg.`
  }

  const roundedMinutes = minutes.toFixed(2)
  return `${roundedMinutes} min.`
}

export const timeFormat = value => {
  if (value < 60) {
    return `${value} min.`
  }
  const hours = Math.floor(value / 60)
  const mins = value % 60
  return `${hours}:${mins} hr`
}

export const orderPaymentFormat = (value, i, el) => (
  <div className="client-table-group">
    <span>{value}</span>
    <span>{el.payment_status_label}</span>
  </div>
)

export const orderActionFormat = (value, i, obj, callback) => (
  <div className="table-clients-center">
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={value}>Ver Orden</Tooltip>}
    >
      <Link className="btn-white btn btn-xs" to={`/orders/${value}`}>
        <i className="fa fa-eye" />
      </Link>
    </OverlayTrigger>

    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={value}>Editar Guía</Tooltip>}
    >
      <button
        type="button"
        onClick={() => callback(obj)}
        className="btn-white btn btn-xs"
      >
        <i className="fa fa-pencil" />
      </button>
    </OverlayTrigger>
  </div>
)

export const routeActionFormat = value => (
  <OverlayTrigger
    placement="top"
    overlay={<Tooltip id={value}>Ver ruta</Tooltip>}
  >
    <Link className="btn-white btn btn-xs" to={`/groups/routing/${value}`}>
      <i className="fa fa-eye" />
    </Link>
  </OverlayTrigger>
)

export const currentVehicleFormat = el => (
  <div className="client-table-group">
    {el ? (
      <div>
        {el.plate && <div>Placa: {el.plate}</div>}
        {el.model && <div>Modelo: {el.model}</div>}
        {el.color && <div>Color: {el.color}</div>}
      </div>
    ) : (
      'Sin vehículo actual'
    )}
  </div>
)

export const vehicleLoadFormat = el => {
  if (!el) return 'Sin vehículo actual'
  if (
    el &&
    !el.max_kg &&
    !el.max_m3 &&
    !el.min_batch_orders &&
    !el.max_batch_orders
  ) {
    return 'No establecida'
  }
  return (
    <div className="client-table-group">
      <div>
        {el.max_kg && <div>Peso: {el.max_kg} Kg</div>}
        {el.max_m3 && (
          <div>
            Volúmen: {el.max_m3} m<sup>3</sup>
          </div>
        )}
        {el.min_batch_orders && <div>Minimo: {el.min_batch_orders}</div>}
        {el.max_batch_orders && <div>Máximo: {el.max_batch_orders}</div>}
      </div>
    </div>
  )
}

export const minutesTohoursFormat = value => {
  let hours
  let minutes
  if (value >= 60) {
    hours = `${parseInt(value / 60, 10)}hr(s)`
    minutes = value - hours * 60
  } else {
    hours = ''
    minutes = value
  }
  return (
    <span className="label label-danger">
      {`Tiempo de Espera: ${hours} ${minutes || '0'}min`}
    </span>
  )
}

export const bodyFormat = value => (
  <div style={{ width: '500px' }}>{value}</div>
)

export const vehicleTypesActionsFormat = (id, deleteCallback) => (
  <div>
    {hasPermission('vehicletype', permissionLevels.UPDATE) && (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={id}>Editar</Tooltip>}
      >
        <Link
          className="btn-white btn btn-xs"
          to={`/vehicle-types/edit/${id}`}
        >
          <i className="fa fa-edit" />
        </Link>
      </OverlayTrigger>
    )}
    {hasPermission('vehicletype', permissionLevels.DELETE) && (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={id}>Eliminar</Tooltip>}
      >
        <button
          type="button"
          className="btn-white btn btn-xs"
          onClick={() => deleteCallback(id)}
        >
          <i className="fa fa-times" />
        </button>
      </OverlayTrigger>
    )}
  </div>
)

export const orderTypesActionsFormat = (id, deleteCallback) => (
  <div>
    {hasPermission('ordertype', permissionLevels.UPDATE) && (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={id}>Editar</Tooltip>}
      >
        <Link className="btn-white btn btn-xs" to={`/order-types/edit/${id}`}>
          <i className="fa fa-edit" />
        </Link>
      </OverlayTrigger>
    )}
    {hasPermission('organization', permissionLevels.DELETE) && (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={id}>Eliminar</Tooltip>}
      >
        <button
          type="button"
          className="btn-white btn btn-xs"
          onClick={() => deleteCallback(id)}
        >
          <i className="fa fa-times" />
        </button>
      </OverlayTrigger>
    )}
  </div>
)

export const contractActionsFormat = id => (
  <div>
    {hasPermission('organizationcontract', permissionLevels.UPDATE) && (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={id}>Editar</Tooltip>}
      >
        <Link className="btn-white btn btn-xs" to={`/my-network/edit/${id}`}>
          <i className="fa fa-edit" />
        </Link>
      </OverlayTrigger>
    )}
  </div>
)

export const typeOriginFormat = value =>
  value === 'corp' ? 'Corporativo' : 'Privado'

export const clientListFullNameFormat = (value, i, obj) =>
  `${obj.name} ${obj.last_name}`

export const ordersCountFormat = value => value.length || 'Sin órdenes'

export const progressBarFormat = value => (
  <div className="u-flex-center">
    <small className="u-bold">{`${value}%`}</small>
    <div className="progress progress-striped active m-b-sm progress-bar-width">
      <div style={{ width: `${value}%` }} className="progress-bar" />
    </div>
  </div>
)

export const paymentTypeFormat = value => value || 'Cupón'

export const googleMapLinkFormat = value => (
  <a
    href={`https://www.google.com/maps/place/${value}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    Ver en mapa
  </a>
)

export const orderLogFormat = (value, i, obj) =>
  obj.started_at ? (
    <p>
      Iniciado: {obj.started_at} <br />
      {obj.completed_at && `Completado: ${obj.completed_at}`}
      {obj.failed_at && `Fallido: ${obj.failed_at}`}
    </p>
  ) : (
    '-'
  )

export const routingDurationFormat = (value, i, obj) => (
  <p>
    Estimado: {timeFormat(obj.estimate_minutes_duration)} <br />
    Real:{' '}
    {obj.real_duration_minutes ? timeFormat(obj.real_duration_minutes) : '-'}
  </p>
)

export const paymentPeriodFormat = (value, i, obj) => (
  <div>
    <p>
      <strong>Inicio:</strong> {dateFormat(obj.start_date)} <br />
      <strong>Fin:</strong> {dateFormat(obj.end_date)}
    </p>
  </div>
)

export const orderTypesTagFormat = elements =>
  elements &&
  elements.map(type => (
    <small className="type-tag" key={type.id}>
      {type.code}
    </small>
  ))

export const paymentTypeTagFormat = elements =>
  elements === 1 ? (
    <span className="label label-info">FACTURA</span>
  ) : (
    <span className="label label-info">Rec. Honorarios</span>
  )

export const vehicleCourierFormat = (arrayValue, elArr) =>
  arrayValue && arrayValue[0][elArr]

export const soatDateFormat = (vehicles, toAdminRepresentation) => {
  // eslint-disable-next-line func-names
  if (vehicles) {
    vehicles.forEach(vehicle => {
      if (vehicle.soat_expiration_date) {
        vehicle.soat_expiration_date = toAdminRepresentation
          ? vehicle.soat_expiration_date
              .split('-')
              .reverse()
              .join('/')
          : vehicle.soat_expiration_date
              .split('/')
              .reverse()
              .join('-')
      }
    })
  }
}

export const adminActionsFormat = id =>
  hasPermission('administrator', permissionLevels.UPDATE) && (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={id}>Editar</Tooltip>}
    >
      <Link className="btn-white btn btn-xs" to={`admins/edit/${id}`}>
        <i className="fa fa-edit" />
      </Link>
    </OverlayTrigger>
  )
