import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'

import { InfiniteList } from '../components/03-components/InfiniteScroll'
import LTrackingMap from '../components/03-components/TrackingMap'
import CardList from '../components/04-layouts/CardList'
import CardListInfinite from '../components/04-layouts/CardListInfinite'
import { PhotoCard } from '../components/04-layouts/Card'
import OrderView from '../components/05-pages/order/detail/OrderDetail'

import * as orderActions from '../actions/orderActions'
import * as dashboardActions from '../actions/dashboardActions'
import * as courierActions from '../actions/courierActions'
import * as orderTypeActions from '../actions/orderTypeActions'
import * as wsActions from '../actions/wsActions'
import * as vehicleTypeActions from '../actions/vehicleTypeActions'

const CONFIG = [
  {
    type: 'select',
    options: {
      multiple: true
    },
    name: 'type',
    title: 'Tipos de Pedidos',
    elements: [],
    customStyle: {
      width: '50%',
      maxWidth: '365px',
      marginleft: '1rem',
      marginRight: 0
    }
  },
  {
    type: 'select',
    title: 'Fecha de Creación',
    options: {
      multiple: false
    },
    name: 'created_at_0',
    elements: [
      {
        value: moment()
          .startOf('day')
          .toISOString(),
        label: 'Hoy',
        selected: true
      },
      {
        value: '',
        label: 'Todos',
        selected: false
      }
    ],
    customStyle: {
      width: '50%',
      maxWidth: '365px',
      marginLeft: 0,
      marginRight: '1rem'
    }
  }
]

/**
 * Manage dashboard realtime data.
 */
class DashboardContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openToggle: false
    }
  }

  componentDidMount() {
    this.initializeDashboard()
  }

  componentDidUpdate(prevProps) {
    this.handleUpdates(prevProps)
  }

  componentWillUnmount() {
    this.cleanupDashboard()
  }

  initializeDashboard = () => {
    const { orderFilters, courierActive } = this.props.dashboard
    const {
      getOrderTypes,
      courierList,
      shouldUseTracker,
      wsEvents,
      getVehicleTypes,
      orderListDashboard
    } = this.props
    const filters = {
      ...orderFilters,
      created_at_0: moment()
        .startOf('day')
        .toISOString()
    }
    if (courierActive) {
      filters.courier = courierActive.id
    }
    getOrderTypes()
    courierList({ pagination: false, is_active: true, is_online: true })
    shouldUseTracker()
    wsEvents()
    getVehicleTypes()
    orderListDashboard(filters)
  }

  handleUpdates = prevProps => {
    const {
      dashboard,
      orderListDashboard,
      startPollingTracker,
      courierList
    } = this.props

    if (dashboard.orderFilters !== prevProps.dashboard.orderFilters) {
      orderListDashboard(dashboard.orderFilters)
    }

    if (
      dashboard.orderNotification &&
      dashboard.orderNotification !== prevProps.dashboard.orderNotification
    ) {
      orderListDashboard(dashboard.orderFilters)
    }

    if (
      dashboard.needRefresh &&
      dashboard.needRefresh !== prevProps.dashboard.needRefresh
    ) {
      orderListDashboard(dashboard.orderFilters)
    }

    if (!dashboard.isPolling && dashboard.useTracker && dashboard.mapCenter) {
      startPollingTracker(dashboard.mapCenter)
    }

    if (
      dashboard.couriers &&
      dashboard.couriers.needRefresh &&
      dashboard.couriers.needRefresh !==
        prevProps.dashboard.couriers?.needRefresh
    ) {
      courierList({
        pagination: false,
        is_active: true,
        is_online: true,
        vehicle_type: dashboard.couriers.filters.vehicle_type
      })
    }
  }

  cleanupDashboard = () => {
    const {
      resetOrderTypes,
      cleanOrderFilters,
      orderDetailClose,
      closeWS,
      stopPollingTracker
    } = this.props

    resetOrderTypes()
    cleanOrderFilters()
    orderDetailClose()
    closeWS('ORDER_DETAIL')
    stopPollingTracker()
  }

  handleToggle = () => {
    this.setState(prevState => {
      return { openToggle: !prevState.openToggle }
    })
  }

  openDetail = () => {
    this.setState({ openToggle: true })
  }

  getConfig = () => {
    const { orderTypesList } = this.props
    if (orderTypesList.length) {
      CONFIG[0].elements = this.parseOrderTypes(orderTypesList)
      return CONFIG
    }
    return []
  }

  parseOrderTypes = orderTypes =>
    orderTypes.map(type => ({
      value: type.id,
      label: type.name,
      selected: false
    }))

  parseVehicleTypes = vehicleTypes =>
    vehicleTypes.map(type => ({
      value: type.id,
      label: type.name,
      selected: false
    }))

  render() {
    const {
      dashboard: {
        courierData,
        orderDetailVisibility,
        orderData,
        orderActive,
        orderFilters,
        orderMapVisibility,
        mapCenter,
        zoom,
        notes,
        courierActive
      },
      history,
      orderDetailUpdate,
      deleteCourierActive,
      orderDetailClose,
      updateOrderDashboardFilters,
      orderDashboardActions,
      orderNoteUpdate,
      orderMapClick,
      orderClick,
      courierMapClick,
      orderUpdate,
      vehicleTypes,
      updateCourierFilters
    } = this.props

    const { openToggle } = this.state

    return (
      <div className="fh-breadcrumb row u-align-flex">
        <div className="fh-column col-lg-3 dashboard-col u-hidden">
          <div style={{ width: '100%' }}>
            <div style={{ padding: '10px 0 0 10px', margin: '0' }}>
              <button
                className="btn-white btn btn-secondary btn-sm dim"
                type="button"
                onClick={() => window.location.reload(false)}
              >
                <i className="fa fa-refresh e-icon" />
              </button>
            </div>
            {orderData && (
              <CardListInfinite
                config={this.getConfig()}
                dashboardProps={this.props.dashboard}
                activeList={orderFilters.group}
                filters={[
                  {
                    group: ['searching'],
                    value: 'pending',
                    icon: 'spinner',
                    label: 'Pendientes'
                  },
                  {
                    group: ['accepted', 'in_progress'],
                    value: 'ongoing',
                    icon: 'check-square-o',
                    label: 'En curso'
                  },
                  {
                    group: ['ended', 'cancelled', 'no_courier'],
                    value: 'finished',
                    icon: 'ban',
                    label: 'Finalizados'
                  }
                ]}
                count={orderData.count}
                closeDetail={orderDetailClose}
                courierActive={courierActive}
                deleteCourierActive={deleteCourierActive}
                updateFilters={updateOrderDashboardFilters}
                groupable
                searchFields={['title', 'subtitle1', 'subtitle2']}
                searchable
              >
                <InfiniteList
                  totalPages={orderData.num_pages}
                  previusPage={orderFilters.page}
                  orderFilters={orderFilters}
                  orderOptionsClick={orderDashboardActions}
                  updateOrderDashboardFilters={updateOrderDashboardFilters}
                  orderMapClick={orderMapClick}
                  orderClick={orderClick}
                  elements={orderData}
                />
              </CardListInfinite>
            )}
          </div>
        </div>
        <div className="full-height col-lg-7 dashboard-col u-flex-expand">
          {!orderDetailVisibility && (
            <div className="e-btn-map">
              <button
                type="button"
                onClick={this.handleToggle}
                className="btn btn-white btn-bitbucket"
              >
                <i className="fa fa-exchange" />
              </button>
            </div>
          )}
          <LTrackingMap
            fnHistoryPush={history.push}
            defaultCenter={mapCenter}
            center={mapCenter}
            fnCourierClick={courierMapClick}
            zoom={zoom}
            courierData={courierData || []}
            orderActive={orderActive}
            courierActive={courierActive}
            orderMapVisibility={orderMapVisibility}
            onOrderUpdate={orderUpdate}
          />
          {orderActive && orderDetailVisibility && (
            <div className="dashboard-order-detail">
              <OrderView
                newNotes={notes}
                newOrderDetail={orderDetailUpdate.data}
                orderClick={orderClick}
                orderUpdate={orderUpdate}
                orderNoteUpdate={orderNoteUpdate}
                data={this.props.dashboard.data}
                {...orderActive}
                onOrderDetailClose={orderDetailClose}
              />
            </div>
          )}
        </div>
        <div
          className={`fh-column col-lg-2 dashboard-col ${
            openToggle ? 'u-show' : 'u-hide'
          }`}
          style={{ width: '350px' }}
        >
          {this.parseVehicleTypes(vehicleTypes).length ? (
            <CardList
              config={[
                {
                  type: 'select',
                  options: {
                    multiple: true
                  },
                  title: 'Tipos de Vehículos',
                  name: 'vehicle_type',
                  size: '100%',
                  elements: this.parseVehicleTypes(vehicleTypes),
                  customStyle: { width: '100%', maxWidth: '320px' }
                }
              ]}
              updateFilters={updateCourierFilters}
              filters={[
                {
                  value: 'available',
                  busy_level: 'free',
                  icon: 'circle',
                  label: 'Disponible',
                  style: { color: '#1ab394' }
                },
                {
                  value: 'partial',
                  busy_level: 'partial',
                  icon: 'circle',
                  label: 'Parcial',
                  style: { color: 'orange' }
                },
                {
                  value: 'busy',
                  busy_level: 'full',
                  icon: 'circle',
                  label: 'Full',
                  style: { color: 'red' }
                }
              ]}
              searchFields={['title']}
              searchable
            >
              {courierData &&
                courierData.length &&
                courierData.map(courier => (
                  <PhotoCard
                    openDetail={courier.position ? this.openDetail : () => {}}
                    courierMapClick={
                      courier.position ? courierMapClick : () => {}
                    }
                    key={courier.id}
                    element={courier}
                    photo={courier.photo}
                    title={`${courier.name} ${courier.last_name}`}
                    subtitle={courier.phone}
                    filter={courier.busy_level}
                  />
                ))}
            </CardList>
          ) : null}
        </div>
      </div>
    )
  }
}

DashboardContainer.propTypes = {
  dashboard: PropTypes.object.isRequired,
  orderDetailUpdate: PropTypes.object.isRequired,
  orderTypesList: PropTypes.array.isRequired,
  vehicleTypes: PropTypes.array.isRequired,
  couriers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getOrderTypes: PropTypes.func.isRequired,
  courierList: PropTypes.func.isRequired,
  shouldUseTracker: PropTypes.func.isRequired,
  wsEvents: PropTypes.func.isRequired,
  getVehicleTypes: PropTypes.func.isRequired,
  orderListDashboard: PropTypes.func.isRequired,
  resetOrderTypes: PropTypes.func.isRequired,
  cleanOrderFilters: PropTypes.func.isRequired,
  orderDetailClose: PropTypes.func.isRequired,
  closeWS: PropTypes.func.isRequired,
  stopPollingTracker: PropTypes.func.isRequired,
  startPollingTracker: PropTypes.func.isRequired,
  orderNoteUpdate: PropTypes.func.isRequired,
  orderUpdate: PropTypes.func.isRequired,
  orderClick: PropTypes.func.isRequired,
  updateOrderDashboardFilters: PropTypes.func.isRequired,
  orderMapClick: PropTypes.func.isRequired,
  orderDashboardActions: PropTypes.func.isRequired,
  deleteCourierActive: PropTypes.func.isRequired,
  courierMapClick: PropTypes.func.isRequired,
  updateCourierFilters: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  dashboard: state.dashboard,
  orderDetailUpdate: state.orderDetail,
  orderTypesList: state.global.orderTypes.data,
  vehicleTypes: state.global.vehicleTypes.data,
  couriers: state.courierList
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      orderNoteUpdate: orderActions.orderNoteUpdate,
      orderUpdate: orderActions.orderUpdate,
      orderClick: dashboardActions.orderClick,
      updateOrderDashboardFilters: orderActions.updateOrderDashboardFilters,
      orderMapClick: dashboardActions.orderMapClick,
      orderDetailClose: dashboardActions.orderDetailClose,
      orderListDashboard: orderActions.orderListDashboard,
      courierList: courierActions.courierList,
      updateCourierFilters: courierActions.updateCourierFilters,
      wsEvents: wsActions.wsEvents,
      courierMapClick: dashboardActions.courierMapClick,
      cleanOrderFilters: orderActions.cleanOrderFilters,
      orderDashboardActions: orderActions.orderDashboardActions,
      deleteCourierActive: dashboardActions.deleteCourierActive,
      getOrderTypes: orderTypeActions.getOrderTypes,
      resetOrderTypes: orderTypeActions.resetOrderTypes,
      closeWS: wsActions.closeWS,
      shouldUseTracker: dashboardActions.shouldUseTracker,
      startPollingTracker: dashboardActions.startPollingTracker,
      stopPollingTracker: dashboardActions.stopPollingTracker,
      getVehicleTypes: vehicleTypeActions.getVehicleTypes
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
