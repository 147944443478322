import React from 'react'

import { dateFormat } from '../../../../utils/formats'

export const OrderActivity = ({ orderActivity }) => (
  <div className="panel-body">
    <div className="activity-stream">
      {orderActivity &&
        orderActivity.reverse().map(activity => (
          <div key={activity.datetime} className="stream">
            <div className="stream-badge">
              <i className="fa fa-tag bg-primary" />
            </div>
            <div className="stream-panel">
              <div className="stream-info">
                <span className="date">{dateFormat(activity.datetime)}</span>
              </div>
              <p>{activity.description}</p>
              <div style={styles.activityDetails}>
                {activity.reference_link ? (
                  <>
                    <a
                      href={activity.reference_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={styles.link}
                    >
                      Ver en Google Maps
                    </a>{' '}
                    |{' '}
                  </>
                ) : null}
                {activity.address ? (
                  <>
                    <span style={styles.activityPoint}>
                      Punto: <strong>{activity.number}</strong> |{' '}
                    </span>
                    <span className="label label-default" style={styles.label}>
                      {activity.address}
                    </span>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        ))}
    </div>
  </div>
)

// Objeto de estilos en formato de React (JSX)
const styles = {
  activityDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    flexWrap: 'wrap'
  },
  link: {
    marginRight: '10px'
  },
  activityPoint: {
    marginRight: '5px'
  },
  label: {
    display: 'inline-block',
    padding: '5px'
  }
}
