import $ from 'jquery'
import { CALL_API } from '../middleware/api'

export const CLIENT_LIST_REQUEST = 'CLIENT_LIST_REQUEST'
export const CLIENT_LIST_SUCCESS = 'CLIENT_LIST_SUCCESS'
export const CLIENT_LIST_FAILURE = 'CLIENT_LIST_FAILURE'

export const CLIENT_SEARCH_LIST_REQUEST = 'CLIENT_SEARCH_LIST_REQUEST'
export const CLIENT_SEARCH_LIST_SUCCESS = 'CLIENT_SEARCH_LIST_SUCCESS'
export const CLIENT_SEARCH_LIST_FAILURE = 'CLIENT_SEARCH_LIST_FAILURE'

export const UPDATE_CLIENT_FILTERS = 'UPDATE_CLIENT_FILTERS'
export const SELECT_CLIENT = 'SELECT_CLIENT'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const RESET_CONFIGCLIENT = 'RESET_CONFIGCLIENT'

export const CLIENT_DETAIL_REQUEST = 'CLIENT_DETAIL_REQUEST'
export const CLIENT_DETAIL_SUCCESS = 'CLIENT_DETAIL_SUCCESS'
export const CLIENT_DETAIL_FAILURE = 'CLIENT_DETAIL_FAILURE'

export const MERCHANT_DETAIL_REQUEST = 'MERCHANT_DETAIL_REQUEST'
export const MERCHANT_DETAIL_SUCCESS = 'MERCHANT_DETAIL_SUCCESS'
export const MERCHANT_DETAIL_FAILURE = 'MERCHANT_DETAIL_FAILURE'

export const CLIENT_UPDATE_REQUEST = 'CLIENT_UPDATE_REQUEST'
export const CLIENT_UPDATE_SUCCESS = 'CLIENT_UPDATE_SUCCESS'
export const CLIENT_UPDATE_FAILURE = 'CLIENT_UPDATE_FAILURE'

export const CLIENT_DELETE_REQUEST = 'CLIENT_DELETE_REQUEST'
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS'
export const CLIENT_DELETE_FAILURE = 'CLIENT_DELETE_FAILURE'

export const CLIENT_TOKEN_REQUEST = 'CLIENT_TOKEN_REQUEST'
export const CLIENT_TOKEN_SUCCESS = 'CLIENT_TOKEN_SUCCESS'
export const CLIENT_TOKEN_FAILURE = 'CLIENT_TOKEN_FAILURE'

export const CLIENT_SUMMARY_REQUEST = 'CLIENT_SUMMARY_REQUEST'
export const CLIENT_SUMMARY_SUCCESS = 'CLIENT_SUMMARY_SUCCESS'
export const CLIENT_SUMMARY_FAILURE = 'CLIENT_SUMMARY_FAILURE'

export const CLIENT_UPGRADE_REQUEST = 'CLIENT_UPGRADE_REQUEST'
export const CLIENT_UPGRADE_SUCCESS = 'CLIENT_UPGRADE_SUCCESS'
export const CLIENT_UPGRADE_FAILURE = 'CLIENT_UPGRADE_FAILURE'

export const CLIENT_VIRTUALPOINTS_REQUEST = 'CLIENT_VIRTUALPOINTS_REQUEST'
export const CLIENT_VIRTUALPOINTS_SUCCESS = 'CLIENT_VIRTUALPOINTS_SUCCESS'
export const CLIENT_VIRTUALPOINTS_FAILURE = 'CLIENT_VIRTUALPOINTS_FAILURE'

export const UPDATE_CLIENTVIRTUALPOINTS_FILTERS =
  'UPDATE_CLIENTVIRTUALPOINTS_FILTERS'

export function clientList(params = {}) {
  let url = 'clients/'

  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [CLIENT_LIST_REQUEST, CLIENT_LIST_SUCCESS, CLIENT_LIST_FAILURE]
    }
  }
}

export function clientSearchList() {
  const url = 'clients/orders/active/'

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      types: [
        CLIENT_SEARCH_LIST_REQUEST,
        CLIENT_SEARCH_LIST_SUCCESS,
        CLIENT_SEARCH_LIST_FAILURE
      ]
    }
  }
}

export function updateClientFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_CLIENT_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function clientDetail(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `clients/${id}/`,
      authenticated: true,
      types: [
        CLIENT_DETAIL_REQUEST,
        CLIENT_DETAIL_SUCCESS,
        CLIENT_DETAIL_FAILURE
      ]
    }
  }
}

export function merchantDetail(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `merchant/client/${id}/`,
      authenticated: true,
      storeAPI: true,
      types: [
        MERCHANT_DETAIL_REQUEST,
        MERCHANT_DETAIL_SUCCESS,
        MERCHANT_DETAIL_FAILURE
      ]
    }
  }
}

export function editClient(id, data) {
  if (data.password === '') delete data.password

  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `clients/${id}/`,
      authenticated: true,
      data,
      types: [
        CLIENT_UPDATE_REQUEST,
        CLIENT_UPDATE_SUCCESS,
        CLIENT_UPDATE_FAILURE
      ]
    }
  }
}

export function clientDelete(id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `clients/${id}/`,
      authenticated: true,
      types: [
        CLIENT_DELETE_REQUEST,
        CLIENT_DELETE_SUCCESS,
        CLIENT_DELETE_FAILURE
      ]
    }
  }
}

export function selectClient(obj) {
  return dispatch => {
    const action = {
      type: SELECT_CLIENT,
      data: obj
    }
    dispatch(action)
  }
}

export function showMessage() {
  return dispatch => {
    const action = {
      type: SHOW_MESSAGE
    }
    dispatch(action)
  }
}

export function hideMessage() {
  return dispatch => {
    const action = {
      type: HIDE_MESSAGE
    }
    dispatch(action)
  }
}

/** Login on behalf of a client */
export function clientToken(id) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `clients/${id}/token/`,
      authenticated: true,
      types: [CLIENT_TOKEN_REQUEST, CLIENT_TOKEN_SUCCESS, CLIENT_TOKEN_FAILURE]
    }
  }
}

export function resetConfigClient() {
  return dispatch => {
    const action = {
      type: RESET_CONFIGCLIENT
    }
    dispatch(action)
  }
}

export function getClientSummary(params = {}) {
  let url = 'orders/summary/'

  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      types: [
        CLIENT_SUMMARY_REQUEST,
        CLIENT_SUMMARY_SUCCESS,
        CLIENT_SUMMARY_FAILURE
      ]
    }
  }
}

export function upgradeToOrganization(id) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `clients/${id}/upgrade_to_organization/`,
      authenticated: true,
      types: [
        CLIENT_UPGRADE_REQUEST,
        CLIENT_UPGRADE_SUCCESS,
        CLIENT_UPGRADE_FAILURE
      ]
    }
  }
}

export function clientVirtualPoints(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `clients/${id}/virtual_points/`,
      authenticated: true,
      types: [
        CLIENT_VIRTUALPOINTS_REQUEST,
        CLIENT_VIRTUALPOINTS_SUCCESS,
        CLIENT_VIRTUALPOINTS_FAILURE
      ]
    }
  }
}

export function updateClientVirtualPoints(data) {
  return dispatch => {
    const action = {
      type: UPDATE_CLIENTVIRTUALPOINTS_FILTERS,
      data
    }
    dispatch(action)
  }
}
