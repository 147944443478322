import React, { Component } from 'react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as moment from 'moment'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'

import ActionButton from '../../../01-atoms/ActionButton'
import Spinner from '../../../01-atoms/SpinnerLoader/Spinner'
import Status from '../../../01-atoms/Status'
import Toast from '../../../01-atoms/Toast'
import Header from '../../../04-layouts/Header'
import OrderNote from './OrderNote'
import OrderPrices from './OrderPrices'
import { OrderActivity } from './OrderActivity'
import OrderDestinations from './OrderDestinations'
import { OrderPayment } from './OrderPayment'
import * as orderActions from '../../../../actions/orderActions'
import generateFetch from '../../../../utils/fetch'
import {
  dateFormat,
  kilometerFormat,
  kmDistanceFormat,
  minDurationFormat,
  minutesTohoursFormat,
  priceFormat
} from '../../../../utils/formats'
import { hasPermission, showClient } from '../../../../utils/index'
import {
  DESTINATION_STATUS,
  ORDER_STATUS,
  permissionLevels
} from '../../../../utils/variables'

const openTabFive = e => {
  e.preventDefault()
  const selectorTab = '.js-tabs-orderDetail .nav-tabs'
  const selectorActiveDetailTab = `${selectorTab} .active`
  const selectorAllTabs = `${selectorTab} li`
  const selectorContentTab = '.js-content-detail'
  const AllTabs = document.querySelectorAll(selectorAllTabs)
  const AllContentTabs = document.querySelectorAll(
    `${selectorContentTab} .tab-pane`
  )

  document.querySelector(selectorActiveDetailTab).className = ''
  document.querySelector(`${selectorContentTab} .active`).className =
    'tab-pane'

  AllTabs[AllTabs.length - 1].className = 'active'
  AllContentTabs[AllContentTabs.length - 1].className = 'tab-pane active'
}

const selectDestinationStatus = [
  { value: '', label: 'Seleccionar estado' },
  { value: DESTINATION_STATUS.FAILED, label: 'Fallido' },
  { value: DESTINATION_STATUS.COMPLETED, label: 'Completado' }
]

const staticMedia = `${process.env.REACT_APP_S3_URL}media/`
const basepath = `${process.env.REACT_APP_BASE_DOMAIN}api/admin/orders/`

class OrderView extends Component {
  state = {
    editingDestination: null,
    selectedStatus: '',
    failedReason: '',
    otherReason: '',
    toastMessage: '',
    toastType: '',
    showModal: false,
    showReturnModal: false,
    priceDetails: null,
    modalType: '',
    lookupId: null,
    isConformable: false,
    isLoading: false
  }

  submitPriceCostModification = value => {
    const { id, orderUpdate } = this.props

    const values = (({
      cost_modification,
      cost_modification_comment,
      total_cost,
      price_modification,
      price_modification_comment,
      total_price
    }) => ({
      cost_modification,
      cost_modification_comment,
      total_cost,
      price_modification,
      price_modification_comment,
      total_price
    }))(value)

    Object.keys(values).forEach(
      k => (!values[k] || typeof values[k] === 'number') && delete values[k]
    )

    orderUpdate(id, values)
  }

  componentDidUpdate(prevProps) {
    if (this.props.toast.visible && !prevProps.toast.visible) {
      setTimeout(() => {
        this.props.dispatch({ type: orderActions.HIDE_TOAST })
      }, 8000)
    }
  }

  chargeFromPurseHandler = () => {
    const { chargeFromPurse, id } = this.props
    const request = chargeFromPurse({
      payment_request: { backend: 'purse' },
      order_id: id
    })
    request.then(() => {
      window.location = '/orders/'
    })
  }

  retryPaymentHandler = () => {
    const { retryPayment, id } = this.props
    const request = retryPayment({ order_id: id })
    request.then(() => {
      window.location = '/orders/'
    })
  }

  handleCheckboxChange = e => {
    this.setState({ isConformable: e.target.checked })
  }

  handleDestinationChange = dest => {
    this.setState({
      editingDestination: dest,
      showModal: true,
      modalType: 'confirmDestinationChange'
    })
  }

  handleCloseModal = () => {
    this.setState({ showModal: false, priceDetails: null })
  }

  handleCloseRenderPriceModal = () => {
    this.setState({ showReturnModal: false, modalType: 'renderPrice' })
  }

  closeToast = () => {
    this.setState({ toastMessage: '', toastType: '' })
  }

  renderModal = (body, onConfirm, onCancel) => {
    const { showModal } = this.state
    return (
      <Modal show={showModal} onHide={onCancel}>
        <Modal.Body>
          <div className="form-group row ">
            <div className="text-center">
              <h4>{body}</h4>
              <div className="col-sm-12">
                <button
                  type="button"
                  onClick={onCancel}
                  className="btn btn-white"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  onClick={onConfirm}
                  className="btn btn-primary"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  submitDestinationEdit = async destination_number => {
    const { id, orderDetail } = this.props
    const { selectedStatus, failedReason, otherReason } = this.state

    if (!selectedStatus) {
      this.setState({
        toastMessage: 'Debe seleccionar un estado',
        toastType: 'error'
      })
      setTimeout(() => {
        this.closeToast()
      }, 5000)
      return
    }

    const data = { status: selectedStatus }
    if (selectedStatus === DESTINATION_STATUS.FAILED && failedReason) {
      data.failed_reason =
        failedReason === 'Otros' ? otherReason : failedReason
    }

    const config = {
      method: 'PATCH',
      data: data,
      url: `${basepath}${id}/${destination_number}/`
    }
    try {
      const response = await generateFetch(config)
      this.setState({
        toastMessage: 'Estado del destino actualizado exitosamente',
        toastType: 'success',
        editingDestination: null
      })
      setTimeout(() => {
        this.closeToast()
      }, 5000)

      await orderDetail(id)
    } catch (error) {
      const apiErrorResponse = error.response?.data
      let apiErrorMessage = 'Error al actualizar el estado del destino'

      if (Array.isArray(apiErrorResponse)) {
        apiErrorMessage = apiErrorResponse.join(' ')
      } else if (apiErrorResponse?.non_field_errors) {
        apiErrorMessage = apiErrorResponse.non_field_errors.join(' ')
      } else if (typeof apiErrorResponse === 'string') {
        apiErrorMessage = apiErrorResponse
      }

      this.setState({
        toastMessage: apiErrorMessage,
        toastType: 'error',
        editingDestination: null
      })
      setTimeout(() => {
        this.closeToast()
      }, 5000)

      console.error('Error al editar destino:', error)
    }
  }

  returnPriceCalculation = async () => {
    const { id } = this.props
    const requestOpt = {
      method: 'POST',
      url: `${basepath}${id}/return/calculate_price/`
    }
    try {
      const res = await generateFetch(requestOpt)
      const { lookup_id } = res
      this.setState({
        priceDetails: res.data,
        showReturnModal: true,
        isConformable: false,
        lookupId: lookup_id
      })
    } catch (error) {
      this.setState({
        toastMessage: 'Error al calcular el precio del retorno',
        toastType: 'error'
      })
      setTimeout(() => {
        this.closeToast()
      }, 5000)

      console.log('Error en la solicitud de cálculo de precio:', error)
    }
  }

  submitOrderAddReturn = async () => {
    const { id, orderDetail } = this.props
    const { lookupId } = this.state

    const requestOptions = {
      method: 'POST',
      url: `${basepath}${id}/return/add/`,
      body: JSON.stringify({ lookup_id: lookupId })
    }
    this.setState({ isLoading: true })
    try {
      const response = await generateFetch(requestOptions)

      this.setState({
        showReturnModal: false,
        toastMessage: 'El retorno ha sido agregado correctamente',
        toastType: 'success',
        isLoading: false
      })
      setTimeout(() => {
        this.closeToast()
      }, 5000)

      await orderDetail(id)
    } catch (error) {
      const apiErrorResponse = error.response?.data
      this.setState({
        showReturnModal: false,
        toastMessage: apiErrorResponse || 'Error al agregar el retorno',
        toastType: 'error',
        isLoading: false
      })
      setTimeout(() => {
        this.closeToast()
      }, 5000)

      console.log('Error al agregar retorno a la orden:', error)
    }
  }

  renderEditDestinationForm = () => {
    const { statusReasonOptions, getStatusReasonOptions, client } = this.props
    const {
      editingDestination,
      selectedStatus,
      failedReason,
      otherReason
    } = this.state

    if (!editingDestination) return null

    return (
      <div
        className="row jumbotron inline-action"
        style={{ marginBottom: '24px' }}
      >
        <div className="col-lg-6 col-lg-offset-3">
          <form
            onSubmit={e => {
              e.preventDefault()
              this.handleDestinationChange(editingDestination)
            }}
          >
            <div className="input-group">
              <select
                className="form-control mb"
                name="selectedStatus"
                value={selectedStatus}
                onChange={e => {
                  getStatusReasonOptions({
                    status: e.target.value,
                    client: client.id
                  })
                  this.setState({ selectedStatus: e.target.value })
                }}
              >
                {selectDestinationStatus.map((el, index) => (
                  <option key={index} value={el.value}>
                    {el.label}
                  </option>
                ))}
              </select>
              {selectedStatus === DESTINATION_STATUS.FAILED ? (
                <React.Fragment>
                  <div>
                    <select
                      className="form-control mb"
                      required
                      onChange={e =>
                        this.setState({ failedReason: e.target.value })
                      }
                    >
                      <option value="">Seleccione una razón</option>
                      {statusReasonOptions &&
                      statusReasonOptions.status_reason &&
                      statusReasonOptions.status_reason.length ? (
                        <React.Fragment>
                          {statusReasonOptions?.status_reason?.map(el => (
                            <option key={el.id} value={el.description}>
                              {el.description}
                            </option>
                          ))}
                          {statusReasonOptions?.allow_optional_fail_reason && (
                            <option value="Otros">Otros</option>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <option value="Problema con la dirección">
                            Problema con la dirección
                          </option>
                          <option value="El cliente no está">
                            El cliente no está
                          </option>
                          <option value="No quiere el producto">
                            No quiere el producto
                          </option>
                          <option value="Otros">Otros</option>
                        </React.Fragment>
                      )}
                    </select>
                    {failedReason === 'Otros' && (
                      <input
                        className="form-control mb"
                        type="text"
                        placeholder="Indicar motivo"
                        value={otherReason}
                        onChange={e =>
                          this.setState({ otherReason: e.target.value })
                        }
                        required
                      />
                    )}
                  </div>
                </React.Fragment>
              ) : null}
              <div className="input-group-btn">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!selectedStatus}
                >
                  Cambiar
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() =>
                    this.setState({
                      editingDestination: null,
                      selectedStatus: '',
                      failedReason: '',
                      otherReason: ''
                    })
                  }
                >
                  Cancelar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }

  confirmDestinationChange = async () => {
    const { editingDestination } = this.state

    await this.submitDestinationEdit(editingDestination)
    this.setState({
      showModal: false,
      selectedStatus: '',
      editingDestination: null
    })
  }

  cancelDestinationChange = () => {
    this.setState({
      showModal: false,
      selectedStatus: '',
      editingDestination: null,
      modalType: ''
    })
  }

  editDestinationFormat = destination_number => (
    <div>
      {hasPermission('destination', permissionLevels.UPDATE) && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={destination_number}>Editar destino</Tooltip>}
        >
          <button
            type="button"
            className="btn btn-white btn-xs"
            onClick={() =>
              this.setState({ editingDestination: destination_number })
            }
          >
            <i className="fa fa-pencil-square-o" />
          </button>
        </OverlayTrigger>
      )}
    </div>
  )

  renderPriceModal = () => {
    const {
      priceDetails,
      showReturnModal,
      isConformable,
      isLoading
    } = this.state

    if (!priceDetails) return null

    return (
      <Modal show={showReturnModal} onHide={this.handleCloseRenderPriceModal}>
        {isLoading ? (
          ''
        ) : (
          <Modal.Header closeButton>
            <Modal.Title>Cálculo de Precio para Retorno</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px'
          }}
        >
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%'
              }}
            >
              <Spinner />
            </div>
          ) : (
            <div>
              <p>
                <strong>Distancia:</strong>{' '}
                {kmDistanceFormat(priceDetails.distance)}
              </p>
              <p>
                <strong>Duración:</strong>{' '}
                {minDurationFormat(priceDetails.duration)}
              </p>
              {priceDetails.prices.map((price, index) => (
                <div key={index}>
                  <p>
                    <strong>Precio total: </strong>
                    {priceFormat(price.price)}
                  </p>
                  <p>
                    <strong>Precio retorno: </strong>
                    {priceFormat(price.return_price)}
                  </p>
                  <p>
                    <strong>Vehículo: </strong>
                    {price.vehicle_type_name}
                  </p>
                </div>
              ))}
              <div>
                <input
                  type="checkbox"
                  style={{ marginRight: '5px' }}
                  checked={isConformable}
                  onChange={this.handleCheckboxChange}
                />
                <label>
                  ¿El cliente se encuentra conforme con este cambio?
                </label>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-white"
            onClick={this.handleCloseRenderPriceModal}
          >
            Cerrar
          </button>
          <button
            disabled={!isConformable || isLoading}
            onClick={this.submitOrderAddReturn}
            className="btn btn-primary"
          >
            Agregar Retorno
          </button>
        </Modal.Footer>
      </Modal>
    )
  }

  render() {
    const { toastMessage, toastType, modalType } = this.state
    const {
      progress,
      onOrderDetailClose,
      created_at,
      programmed_date,
      status,
      status_label,
      current_destination_label,
      total_waiting_time,
      payment_status,
      isFetching,
      invoice,
      code,
      client,
      description,
      extra_data,
      vehicle_type,
      courier,
      memo,
      id,
      orderNoteUpdate,
      destinations,
      activity,
      notes,
      newNotes,
      payment_type,
      duration,
      distance,
      cost,
      url,
      batch_memo,
      shipping_document,
      coupon_code,
      parent_order_code,
      courier_payment_type_name,
      total_cost,
      courier_earnings_after_igv,
      courier_igv_to_pay,
      payment,
      payment_status_label,
      payment_request,
      toast,
      is_return
    } = this.props

    const tab = '\u00A0'
    return (
      <div className="element-detail-box">
        {!is_return && (
          <Header title="">
            {hasPermission('order', permissionLevels.UPDATE) && (
              <ActionButton
                label="Retorno"
                type="success"
                icon="exchange"
                onClick={this.returnPriceCalculation}
              />
            )}
          </Header>
        )}
        <div className="pull-right progress-in-top">
          <div className="u-flex-center">
            <small className="u-bold u-margin-r-5">
              {`${Math.round(progress * 100)}%`}
            </small>
            <div className="progress progress-striped active m-b-sm progress-bar-width">
              <div
                style={{ width: `${progress * 100}%` }}
                className="progress-bar"
              />
            </div>
          </div>
          <div className="tooltip-demo">
            <button
              type="button"
              className="btn btn-white btn-xs"
              onClick={() => onOrderDetailClose()}
            >
              <i className="fa fa-times" />
            </button>
          </div>
        </div>
        <div className="small text-muted">
          <i className="fa fa-clock-o" /> {moment(created_at).format('LLLL')}
          {programmed_date && (
            <div>
              <i className="fa fa-calendar u-margin-r-5" />
              {dateFormat(programmed_date)}
            </div>
          )}
        </div>
        <br />
        <div>
          <Status
            className="u-margin-r-10"
            value={{
              status,
              status_label
            }}
          />
          {status === 'in_progress' && (
            <Status
              className="u-margin-r-10"
              value={{ status_label: current_destination_label }}
            />
          )}
          <div style={{ display: 'inline-block' }}>
            {minutesTohoursFormat(total_waiting_time)}
          </div>
          {payment_status === 'failed' && (
            <div>
              <ActionButton
                label="Reintentar pago"
                type="primary"
                icon="refresh"
                onClick={this.retryPaymentHandler}
                isDisabled={isFetching}
              />
            </div>
          )}
          {payment_status === 'failed' && invoice === null && (
            <div>
              <ActionButton
                label="Pagar con monedero"
                type="primary"
                icon="credit-card-alt"
                onClick={this.chargeFromPurseHandler}
                isDisabled={isFetching}
              />
            </div>
          )}
        </div>
        <h2>Pedido {code}</h2>
        {parent_order_code && (
          <h3>
            Código Padre:{' '}
            <span style={{ fontWeight: '100' }}>{parent_order_code}</span>
          </h3>
        )}
        <div className="u-flex-center">
          <h3 className="client-detail-container">
            {client && (
              <span className="client-detail-header">
                {showClient(client)}
              </span>
            )}
            {client && client.is_company && (
              <span
                className="client-detail-element"
                style={{ position: 'initial' }}
              >
                <i className="fa fa-user-circle u-margin-r-5" />
                {client.name}
              </span>
            )}

            {client && client.phone && (
              <span
                className="client-detail-element"
                style={{ position: 'initial' }}
              >
                <i className="fa fa-phone u-margin-r-5" />
                {client.phone}
              </span>
            )}
          </h3>
          <span
            style={{ position: 'initial', width: 'initial', maxWidth: '50%' }}
            className="u-ellipsis pull-right"
          >
            <a href="$" onClick={e => openTabFive(e)}>
              <i className="fa fa-comment u-margin-r-5" />
              {description}
            </a>
          </span>
        </div>

        <div className="u-flex-center">
          <div>
            <img
              className="u-margin-r-5"
              width="20"
              src={vehicle_type ? vehicle_type.image : ''}
              alt=""
            />
            {courier ? (
              <Link to={`/couriers/history/${courier.id}`}>
                {courier.name} {courier.last_name}
              </Link>
            ) : (
              '-'
            )}
            {courier && (
              <span
                className="client-detail-element"
                style={{ position: 'initial' }}
              >
                {' '}
                <br />
                <i className="fa fa-phone u-margin-r-5" />
                {courier.phone}
              </span>
            )}
          </div>
        </div>
        <br />
        <div className="u-flex-center">
          <div>
            {memo && (
              <span
                style={{
                  position: 'initial'
                }}
                className="client-detail-element"
              >
                Cod. Referencia o memo:{' '}
                <a href="$" onClick={e => openTabFive(e)}>
                  <i className="fa fa-file-text-o u-margin-r-5" />
                  {memo}
                </a>
              </span>
            )}
          </div>
        </div>
        <br />
        <div>
          {courier_payment_type_name === 'Invoices' && (
            <>
              <br />
              <span
                className="client-detail-element"
                style={{ position: 'initial' }}
              >
                Tipo de pago mensajero:
              </span>
              {tab}
              <span className="label label-info">FACTURA</span>
              <br />
              <span
                className="client-detail-element"
                style={{ position: 'initial' }}
              >
                Costo bruto:{' '}
              </span>
              <span
                className="client-detail-element"
                style={{ position: 'initial' }}
              >
                {total_cost}
              </span>
              {tab}
              <span
                className="client-detail-element"
                style={{ position: 'initial' }}
              >
                IGV:{' '}
              </span>
              <span
                className="client-detail-element"
                style={{ position: 'initial' }}
              >
                {courier_igv_to_pay}
              </span>
              {tab}
              <span
                className="client-detail-header"
                style={{ position: 'initial' }}
              >
                Costo neto:{' '}
              </span>
              <span
                className="client-detail-header"
                style={{ position: 'initial' }}
              >
                {courier_earnings_after_igv}
              </span>
            </>
          )}
        </div>
        <div>
          {courier_payment_type_name === 'Fees' && (
            <>
              <br />
              <span
                className="client-detail-element"
                style={{ position: 'initial' }}
              >
                Tipo de pago mensajero:
              </span>
              {tab}
              <span className="label label-info">Recibo Honorarios</span>
              <br />
              <span
                className="client-detail-header"
                style={{ position: 'initial' }}
              >
                Costo neto:{' '}
              </span>
              <span
                className="client-detail-header"
                style={{ position: 'initial' }}
              >
                {courier_earnings_after_igv}
              </span>
            </>
          )}
        </div>
        <br />
        {this.renderEditDestinationForm()}
        {this.renderPriceModal()}
        {modalType === 'confirmDestinationChange' &&
          this.renderModal(
            '¿Seguro que deseas cambiar el estado del destino?',
            this.confirmDestinationChange,
            this.cancelDestinationChange
          )}
        {toastMessage && (
          <div style={{ marginBottom: '24px' }}>
            <Toast
              message={toastMessage}
              type={toastType}
              onClose={this.closeToast}
            />
          </div>
        )}
        {toast.visible && (
          <div style={{ marginBottom: '24px' }}>
            <Toast
              message={toast.message}
              type={toast.type}
              onClose={() =>
                this.props.dispatch({ type: orderActions.HIDE_TOAST })
              }
            />
          </div>
        )}
        <div className="tabs-container js-tabs-orderDetail">
          <ul className="nav nav-tabs">
            <li className="active">
              <a data-toggle="tab" href="#tab-1" aria-expanded="false">
                Destinos
              </a>
            </li>
            <li className="">
              <a data-toggle="tab" href="#tab-2" aria-expanded="true">
                Actividad
              </a>
            </li>
            <li className="">
              <a data-toggle="tab" href="#tab-3" aria-expanded="true">
                Pago
              </a>
            </li>
            <li className="">
              <a data-toggle="tab" href="#tab-4" aria-expanded="true">
                Precio / Costo
              </a>
            </li>
            <li className="">
              <a data-toggle="tab" href="#tab-5" aria-expanded="true">
                Anotaciones
              </a>
            </li>
            <li className="">
              <a data-toggle="tab" href="#tab-6" aria-expanded="true">
                Más información
              </a>
            </li>
          </ul>
          <div className="tab-content js-content-detail">
            <div id="tab-1" className="tab-pane active ">
              {/* Destination detail */}
              <OrderDestinations
                destinations={destinations}
                editDestinationFormat={this.editDestinationFormat}
              />
            </div>
            <div id="tab-2" className="tab-pane">
              <OrderActivity orderActivity={activity} />
            </div>
            <div id="tab-3" className="tab-pane">
              <OrderPayment
                orderPayment={payment}
                paymentType={payment_type}
                paymentStatus={payment_status}
                paymentStatusLabel={payment_status_label}
                paymentRequest={payment_request}
              />
            </div>
            <div id="tab-4" className="tab-pane">
              <OrderPrices
                submitForm={this.submitPriceCostModification}
                prices={this.props}
              />
            </div>
            <div id="tab-5" className="tab-pane">
              <OrderNote
                orderId={id}
                orderNoteUpdate={orderNoteUpdate}
                orderNotes={notes}
                newNotes={newNotes}
              />
            </div>
            <div id="tab-6" className="tab-pane">
              <div className="panel-body">
                <div className="col-lg-6">
                  <dl className="dl-horizontal">
                    <dt>Medio de pago:</dt> <dd>{payment_type || 'Cupón'}</dd>
                    <dt>Duración:</dt> <dd>{duration}</dd>
                    <dt>Kilometros:</dt> <dd>{kilometerFormat(distance)}</dd>
                    {description && (
                      <React.Fragment>
                        <dt>Descripción:</dt>
                        <dd> {description}</dd>
                      </React.Fragment>
                    )}
                    {coupon_code && (
                      <React.Fragment>
                        <dt>Cupón:</dt>
                        <dd> {coupon_code}</dd>
                      </React.Fragment>
                    )}
                    {extra_data && Object.keys(extra_data).length ? (
                      <React.Fragment>
                        <dt style={{ marginTop: '10px' }}>Campos Extra</dt>
                        <dd />
                        {Object.keys(extra_data).map((activity, index) => (
                          <React.Fragment key={index}>
                            <dt>{activity}:</dt>
                            <dd>{extra_data[activity]}</dd>
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ) : null}
                  </dl>
                </div>
                <div className="col-lg-6">
                  <dl className="dl-horizontal">
                    <dt>Costo:</dt> <dd>{cost}</dd>
                    <dt>Tracking público:</dt>{' '}
                    <dd>
                      <a
                        className="u-ellipsis"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {url}
                      </a>
                    </dd>
                    <dt>Código de Referencia:</dt> <dd>{memo}</dd>
                    <dt>Memo de Ruta:</dt> <dd>{batch_memo}</dd>
                    <dt>Guía de Remisión:</dt> <dd>{shipping_document}</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    )
  }
}

const mapStateToProps = state => {
  let initialValues = {}
  const { orderDetail } = state
  if (
    Object.keys(orderDetail.data).length &&
    orderDetail.selectedOrder !== null &&
    orderDetail.selectedOrder !== undefined
  ) {
    const [selectedOrder] = orderDetail.data.destinations.filter(
      el => el.number === orderDetail.selectedOrder
    )

    if (selectedOrder) {
      const [orderData] = selectedOrder.log.filter(
        log => log.status === ORDER_STATUS.COMPLETED
      )
      if (orderData) {
        initialValues = {
          photo: orderData.extra.photo
            ? staticMedia + orderData.extra.photo
            : '',
          photo2: orderData.extra.photo2
            ? staticMedia + orderData.extra.photo2
            : '',
          signature: orderData.extra.signature
            ? staticMedia + orderData.extra.signature
            : ''
        }
      }
    }
  }

  return {
    isFetching: state.orderDetail.isFetching,
    selectedOrder: state.orderDetail.selectedOrder,
    toast: state.orderDetail.toast,
    dataDetail: state.orderDetail.data,
    statusReasonOptions: state.orderList.statusReasonOptions,
    initialValues
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      retryPayment: orderActions.retryPayment,
      chargeFromPurse: orderActions.chargeFromPurse,
      selectOrderToEditPhoto: orderActions.selectOrderToEditPhoto,
      getStatusReasonOptions: orderActions.getStatusReasonOptions,
      orderUploadPhotos: orderActions.orderUploadPhotos,
      orderDetail: orderActions.orderDetail
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'addPhoto', enableReinitialize: true })(OrderView))
